import React, { FunctionComponent } from "react";
import { Grid } from "semantic-ui-react";
import Layout from "../../components/Layout";

import SideBar, { MobileSidebar } from "./sidebar";
import "../../components/layout.scss";

type Props = {
  children: JSX.Element;
  title: string;
  description?: string;
  keywords?: string;
  section: string;
  site;
  currentPage;
  sidebarPages;
};

const Component: FunctionComponent<Props> = ({
  children,
  title,
  description,
  keywords,
  currentPage,
  section,
}) => (
  <Layout
    section="docs"
    title={title}
    description={description}
    keywords={keywords}
  >
    <Grid
      stackable
      style={{ flexGrow: 1, margin: 0, alignContent: "flex-start" }}
    >
      <Grid.Column computer={3} only="computer">
        {section ? (
          <SideBar currentPage={currentPage} docSection={section} />
        ) : (
          ""
        )}
      </Grid.Column>
      <Grid.Column mobile={16} only="tablet">
        {section ? (
          <MobileSidebar
            title={title}
            currentPage={currentPage}
            docSection={section}
          />
        ) : (
          ""
        )}
      </Grid.Column>
      <Grid.Column tablet={16} computer={9}>
        {children}
      </Grid.Column>
    </Grid>
  </Layout>
);
export default Component;
