import React from "react";
import { Segment, Header } from "semantic-ui-react";

export default function masthead({ title, subheader }) {
  return (
    <Segment>
      <Header as="h1">
        {title}
        <Header as="h2" className="sub header">
          {subheader}
        </Header>
      </Header>
    </Segment>
  );
}
