import React, { Component } from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import {
  Segment,
} from "semantic-ui-react";
import Layout from "./Layout";
import components from "../../components";

export default class MDXRuntime extends Component {
  render() {
    const { data, path } = this.props;
    return (
      <Layout
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.description}
        keywords={data.mdx.frontmatter.keywords}
        site={data.site}
        currentPage={path}
        section={data.mdx.frontmatter.section}
      >
        <MDXProvider components={components}>
          <Segment vertical>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </Segment>
        </MDXProvider>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        keywords
        section
      }
      tableOfContents
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
