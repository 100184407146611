import React, { FunctionComponent } from "react";
import { Link } from "gatsby";
import { Menu, Modal, Icon, Accordion } from "semantic-ui-react";

type Props = {
  currentPage: string;
  style?: any;
  docSection: string;
};

const sidebarPages: SideBarSectionPages = {
  'fundamentals': {
    title: "Fundamentals",
    slug: "/docs/fundamentals",
    pages: [
      {
        title: "Authentication",
        slug: "/docs/fundamentals/authentication",
      },
      {
        title: "Authorization",
        slug: "/docs/fundamentals/authorization",
      },
      {
        title: "Session Management",
        slug: "/docs/fundamentals/sessions",
      },
      {
        title: "Profile Management",
        slug: "/docs/fundamentals/profile",
      },
      {
        title: "Logout",
        slug: "/docs/fundamentals/logout",
      },
      {
        title: "Branding",
        slug: "/docs/fundamentals/branding",
        pages: [
          {
            title: "Custom domain",
            slug: "/docs/fundamentals/branding/domain",
          },
          {
            title: "Login",
            slug: "/docs/fundamentals/branding/authentication",
          },
          {
            title: "Consent",
            slug: "/docs/fundamentals/branding/consent",
          },
          {
            title: "MFA",
            slug: "/docs/fundamentals/branding/challenge",
          },
        ],
      },
    ],
  },
  'guides': {
    title: "Getting Started",
    slug: "/docs/guides",
    pages: [
      {
        title: "Installation",
        slug: "/docs/guides/installation",
      },
      {
        title: "Community",
        slug: "/docs/guides/communities",
      },
      {
        title: "Realm",
        slug: "/docs/guides/realms",
      },
      {
        title: "Applications",
        slug: "/docs/guides/applications",
        pages: [
          {
            title: "Web",
            slug: "/docs/guides/applications/web",
          },
          {
            title: "Native",
            slug: "/docs/guides/applications/native",
          },
          {
            title: "SPA",
            slug: "/docs/guides/applications/spa",
          },
        ],
      },
      {
        title: "APIs",
        slug: "/docs/guides/apis",
      },
    ],
  },
  'develop': {
    title: "Develop",
    slug: "/docs/develop",
    pages: [
      {
        title: "Web Applications",
        slug: "/docs/develop/web",
      },
      {
        title: "SPAs",
        slug: "/docs/develop/spa",
      },
      {
        title: "Native Applications",
        slug: "/docs/develop/native",
      },
      {
        title: "API",
        slug: "/docs/develop/api",
      },
      {
        title: "Do it yourself",
        slug: "/docs/develop/rest",
      },
    ],
  },
  'objects': {
    title: "Object Reference",
    slug: "/docs/reference/object",
    pages: [
      {
        title: "Communities",
        slug: "/docs/reference/object/communities",
      },
      {
        title: "Realms",
        slug: "/docs/reference/object/realms",
      },
      {
        title: "Applications",
        slug: "/docs/reference/object/applications",
      },
      {
        title: "Resource Servers",
        slug: "/docs/reference/object/resource-servers",
      },
      {
        title: "External Auth Servers",
        slug: "/docs/reference/object/external-auth-servers",
      },
      {
        title: "External Clients",
        slug: "/docs/reference/object/external-auth-servers/external-clients",
      },
    ],
  },
  'classes': {
    title: "Class Reference",
    slug: "/docs/reference/class",
    pages: [
      {
        title: "AdministrationContext",
        slug: "/docs/reference/class/AdministrationContext",
      },
      {
        title: "AuthorizationContext",
        slug: "/docs/reference/class/AuthorizationContext",
        pages: [
          {
            title: "Client",
            slug: "/docs/reference/class/AuthorizationContext/Client",
          },
          {
            title: "Request",
            slug: "/docs/reference/class/AuthorizationContext/Request",
          },
        ],
      },
      {
        title: "Browser",
        slug: "/docs/reference/class/Browser",
        pages: [
          {
            title: "Handle",
            slug: "/docs/reference/class/Browser/Handle",
          },
          {
            title: "Sessions",
            slug: "/docs/reference/class/Browser/Sessions",
          },
          {
            title: "Session",
            slug: "/docs/reference/class/Browser/Session",
          },
          {
            title: "SessionVerifications",
            slug: "/docs/reference/class/Browser/SessionVerifications",
          },
          {
            title: "SessionVerification",
            slug: "/docs/reference/class/Browser/SessionVerification",
          },
        ],
      },
      {
        title: "Community",
        slug: "/docs/reference/class/Community",
        pages: [
          {
            title: "Authenticator",
            slug: "/docs/reference/class/Community/Authenticator",
          },
          {
            title: "Authenticators",
            slug: "/docs/reference/class/Community/Authenticators",
          },
          {
            title: "AuthenticatorProvider",
            slug: "/docs/reference/class/Community/AuthenticatorProvider",
          },
        ],
      },
      {
        title: "DatabaseContext",
        slug: "/docs/reference/class/DatabaseContext",
      },
      {
        title: "Remote",
        slug: "/docs/reference/class/Remote",
        pages: [
          {
            title: "Apex",
            slug: "/docs/reference/class/Remote/Apex",
          },
        ],
      },
      {
        title: "SalesforceAuthenticator",
        slug: "/docs/reference/class/SalesforceAuthenticator",
        pages: [
          {
            title: "Email",
            slug: "/docs/reference/class/SalesforceAuthenticator/Email",
          },
          {
            title: "Password",
            slug: "/docs/reference/class/SalesforceAuthenticator/Password",
          },
          {
            title: "Push",
            slug: "/docs/reference/class/SalesforceAuthenticator/Push",
          },
          {
            title: "Totp",
            slug: "/docs/reference/class/SalesforceAuthenticator/Totp",
          },
        ],
      },
      {
        title: "UserContext",
        slug: "/docs/reference/class/UserContext",
        pages: [
          {
            title: "Consent",
            slug: "/docs/reference/class/UserContext/Consent",
          },
          {
            title: "Consents",
            slug: "/docs/reference/class/UserContext/Consents",
          },
          {
            title: "RefreshTokens",
            slug: "/docs/reference/class/UserContext/RefreshTokens",
          },
          {
            title: "Sessions",
            slug: "/docs/reference/class/UserContext/Sessions",
          },
        ],
      },
      {
        title: "WebAuthn",
        slug: "/docs/reference/class/WebAuthn",
        pages: [
          {
            title: "Credential",
            slug: "/docs/reference/class/WebAuthn/Credential",
          },
        ],
      },
    ],
  },
  'extensions': {
    title: "Extensions",
    slug: "/docs/reference/extensions",
    pages: [
      {
        title: "Authenticators",
        slug: "/docs/reference/extensions/authenticators",
      },
      {
        title: "Backchannel Authentication",
        slug: "/docs/reference/extensions/backchannel-authentication",
      },
      {
        title: "Branding",
        slug: "/docs/reference/extensions/branding/consent",
        pages: [
          {
            title: "Consent",
            slug: "/docs/reference/extensions/branding/consent",
          },
          {
            title: "Multifactor Authentication",
            slug: "/docs/reference/extensions/branding/mfa",
          },
          {
            title: "Progressive Profiling",
            slug: "/docs/reference/extensions/branding/progressive-profiling",
          },
        ]
      },
      {
        title: "Claims",
        slug: "/docs/reference/extensions/claims",
      },
    ]
  }
};

const AccordionSideBar: FunctionComponent<Props> = ({ style, currentPage, docSection }) => (
  <Accordion
    as={Menu}
    vertical
    style={{
      overflow: "auto",
      display: "block",
      margin: 0,
      borderRadius: 0,
      width: "100%",
      fontSize: '1.2rem',
      ...style,
    }}
  >
    <SideBarSection {...sidebarPages[docSection]} key={sidebarPages[docSection].slug} currentUrl={currentPage} />
  </Accordion>
);

type SideBarSectionPage = {
  slug: string;
  title: string;
  pages?: SideBarSectionPage[];
};
type SideBarSectionPages = {
  [K: string] : SideBarSectionPage
};


const SideBarSection: FunctionComponent<SideBarSectionPage & {
  currentUrl: string;
}> = ({ slug, title, pages, currentUrl }) => (
  <Menu.Item key={slug}>
    <Accordion.Title
      as={Link}
      active={true}
      to={slug}
      style={{
        display: "block",
        fontWeight: slug === currentUrl ? "bold" : "normal",
        textDecoration: slug === currentUrl ? "underline" : "none",
      }}
    >
      {title}
    </Accordion.Title>
    {Array.isArray(pages) ? (
      <Accordion.Content active={true}>
        <Menu.Menu>
          {pages.map(page => (
            <SideBarSection {...page} key={page.slug} currentUrl={currentUrl} />
          ))}
        </Menu.Menu>
      </Accordion.Content>
    ) : (
      ""
    )}
  </Menu.Item>
);
type MobileProps = {
  currentPage: string;
  title: string;
  docSection: string;
};
const MobileSidebar: FunctionComponent<MobileProps> = ({
  currentPage,
  title,
  docSection
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      closeIcon
      centered={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Menu
          vertical
          style={{
            overflow: "auto",
            display: "block",
            margin: 0,
            borderRadius: 0,
            width: "100%",
          }}
        >
          <Menu.Item className={"active"} to={currentPage} as={Link}>
            <Menu.Header>
              <Icon name="bars" />
              {title}
            </Menu.Header>
          </Menu.Item>
        </Menu>
      }
    >
      <Modal.Content>
        <AccordionSideBar currentPage={currentPage} docSection={docSection} />
      </Modal.Content>
    </Modal>
  );
};
export { MobileSidebar };
export default AccordionSideBar;
