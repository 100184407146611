import React from "react";
import { Table, Message, Segment, Image, Modal, Header } from "semantic-ui-react";
import { Link } from "gatsby";
import CodeBlock from "./codeblock";
import Masthead from "./masthead";

const components = {
  pre: props => <div {...props} />,
  h1: props => <Header as="h1" {...props} />,
  h2: props => <Header as="h2" {...props} />,
  h3: props => <Header as="h3" {...props} />,
  h4: props => <Header as="h4" {...props} />,
  h5: props => <Header as="h5" {...props} />,
  h6: props => <Header as="h6" {...props} />,
  code: props => {
    const language =
      props.className && props.className.replace(/language-/, "");
    const code = props.children.split("\n");
    if (code && code.length && code[code.length - 1] === "") code.pop();
    return (
      <CodeBlock
        language={language}
        code={code.join("\n")}
        title={props.title}
        lineNumbers={props.lineNumbers}
        scroll={props.scroll}
      />
    );
  },
  Masthead,
  table: props => (
    <div style={{ width: "100%", overflow: "auto", padding: "8px 0" }}>
      <Table {...props} celled unstackable collapsing striped />
    </div>
  ),
  a: ({ href, children }) => {
    if (href && (href.startsWith("https://") || href.startsWith("http://"))) {
      return (
        <a href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    }
    return <Link to={href}>{children}</Link>;
  },
  blockquote: ({ children }) => <Message info>{children}</Message>,
  inlineCode: ({ children }) => (
    <code
      style={{
        background: "lightgray",
        padding: "2px 4px",
        borderRadius: "4px",
      }}
    >
      {children}
    </code>
  ),
  p: ({ children }) => <div style={{ margin: "8px 0" }}>{children}</div>,
  img: function DocumentImage({ src, alt }) {
    const [open, setOpen] = React.useState(false);
    return (
      <Segment textAlign="center" basic>
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={
            <Image
              src={src}
              alt={alt}
              centered
              bordered
              style={{
                boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                padding: "16px",
              }}
            />
          }
        >
          <Modal.Content image>
            <Image
              src={src}
              alt={alt}
              centered
              bordered
              style={{
                boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                padding: "16px",
              }}
            />
          </Modal.Content>
        </Modal>
        <span style={{ color: "gray" }}>
          <i>{alt}</i>
        </span>
      </Segment>
    );
  },
};

export default components;
